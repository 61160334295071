import Typewriter from "typewriter-effect";

type TypingLettersProps = {
	value: string;
};

export default function TypingLetters({ value }: TypingLettersProps) {
	return (
		<Typewriter
			options={{
				strings: value,
				autoStart: true,
				loop: true,
			}}
		/>
	);
}
