import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BackgroundCover1 from '../Backgrounds/BackgroundCover1';
import { bgDark, bgLight } from '../config/colors';
import TypingLetters from '../TypingLetter/TypingLetters';
import './MainSlider.scss';

export default function MainSlider() {
    const theme = useTheme();
    return (
        <Container
            id="home"
            disableGutters={true}
            maxWidth={false}
            sx={{
                bgcolor: theme.palette.mode === 'light' ? bgLight : bgDark,
                zIndex: 2,
                opacity: '99.9999%',
            }}
        >
            <BackgroundCover1 />
            <Container sx={{ padding: '56px 16px' }}>
                <Grid container>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Box sx={{ marginTop: '100px' }}>
                            <Typography variant="h3" component="span" color={'warning.light'}>
                                {'A:>'} <TypingLetters value="Welcome to my world " />
                            </Typography>{' '}
                            <Typography variant="h2" component="h2" color={'primary'} className="hero">
                                I'm, Ayub Ansari
                            </Typography>
                            <Typography variant="h4" component="h4" color={'descriptionText.main'} mt={2}>
                                A Software Engineer
                            </Typography>
                            <Box marginTop={5}>
                                <Button
                                    href="#contact"
                                    variant={'contained'}
                                    component={Link}
                                    color="secondary"
                                    size="large"
                                >
                                    Reach Me
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Box marginTop={12}>
                            <img className="dev" alt="ayub" width={'100%'} src="photos/5.jpeg" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
