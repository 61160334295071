import { Box, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BackgroundCover1 from '../Backgrounds/BackgroundCover1';
import { bgDark, bgLight } from '../config/colors';
import './AboutSlider.scss';

export default function AboutSlider() {
    const theme = useTheme();
    return (
        <Container
            id="about"
            disableGutters={true}
            maxWidth={false}
            sx={{
                bgcolor: theme.palette.mode === 'light' ? bgLight : bgDark,
                color: theme.palette.mode === 'light' ? 'background.paper' : 'rgba(114, 0, 255, 1)',
                zIndex: 2,
                opacity: 0.9999,
            }}
        >
            <BackgroundCover1 />
            <Container sx={{ padding: '56px 16px' }}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={12} xs={12} sx={{ textAlign: 'center' }}>
                        <Box padding={2}>
                            <img src={require('../../assets/693.jpg')} alt="" style={{ maxWidth: '80%' }} />
                        </Box>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {' '}
                    </Grid>

                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <Box padding={2}>
                            <Typography variant="h4" color={'warning.light'}>
                                LET’S <br /> INTRODUCE <br /> MYSELF
                            </Typography>
                            <Box>
                                <Typography color="descriptionText.main">
                                    a B.Tech graduate from West Bengal University of Technology in Electronics and
                                    Communication Engineering, passed out in 2016 . After graduate , I chose to become
                                    software developer. I started working with HTML, CSS, JS, PHP, MySQL and Bootstrap.
                                    From then technology is very much evolved, and now I switched to latest technology
                                    like React Js, NodeJs and other popular JS Frameworks. Having more than 6+ years of
                                    experience in web app development, I can now work on any kind of scalable system.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
