import logo from './logo.svg';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './App.css';
import React from 'react';

import PropTypes from 'prop-types';

import useScrollTrigger from '@mui/material/useScrollTrigger';

import Nav from './components/Nav/Nav';
import NavDrawer from './components/Drawer/Drawer';
import MainSlider from './components/MainSlider/MainSlider';
import AboutSlider from './components/AboutSlider/AboutSlider';
import ExpSlider from './components/ExpSlider/ExpSlider';
import { PaletteMode, useMediaQuery } from '@mui/material';
import ThemeContextProvider from './components/ThemeContext/ThemeContextProvider';
import ServicesSlider from './components/ServicesSlider/ServicesSlider';
import WorksSlider from './components/WorksSlider/WorksSlider';
import ContactSlider from './components/ContactSlider/ContactSlider';
import Footer from './components/Footer/Footer';

// const theme = createTheme({

// });

function App(props: any) {
    return (
        <ThemeContextProvider>
            <Nav />
            <MainSlider />
            <AboutSlider />
            <ExpSlider />
            <ServicesSlider />
            <WorksSlider />
            <ContactSlider />
            <Footer />
        </ThemeContextProvider>
    );
}

export default App;
