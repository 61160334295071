// import "./AboutSlider.scss";
import { Container, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import './Footer.scss';

export default function Footer() {
    const theme = useTheme();
    const themeMode = theme.palette.mode;
    const [visitor, setVisitor] = useState(0);

    // useEffect(() => {
    //     fetch('https://api.countapi.xyz/hit/ayubansari.com/visits')
    //         .then((res) => res.json())
    //         .then((json) => {
    //             setVisitor(json.value);
    //         });
    // }, []);

    return (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={{
                color: theme.palette.mode === 'light' ? 'background.paper' : 'rgba(114, 0, 255, 1)',
                zIndex: 2,
                opacity: 0.9999,
            }}
            className={`${themeMode === 'light' ? 'light-navbar-bg' : 'dark-navbar-bg'}`}
        >
            <Container sx={{ padding: '0px' }}>
                <Grid container className="footer">
                    <Grid item md={6} lg={6} xs={12} sm={12} className="details">
                        <Typography variant="body2" padding={3} color={'descriptionText.main'}>
                            made with{' '}
                            <Link
                                href="//reactjs.org"
                                target="_blank"
                                rel="noreferrer"
                                underline="none"
                                color={'warning.main'}
                            >
                                React
                            </Link>{' '}
                            and{' '}
                            <Link
                                href="//mui.com"
                                target={'_blank'}
                                rel="noreferrer"
                                underline="none"
                                color={'warning.main'}
                            >
                                MUI
                            </Link>
                        </Typography>
                    </Grid>

                    <Grid item md={6} lg={6} xs={12} sm={12} className="details">
                        <Typography variant="body2" padding={3} color={'descriptionText.main'}>
                            Visitor :{' '}
                            <a href="#" title="hit counter">
                                <img
                                    style={{ width: 52, height: 14, paddingTop: 2 }}
                                    src="//counter4.optistats.ovh/private/freecounterstat.php?c=l9nchg7pnh1mmflmlc5hxjb4zhhunfd7"
                                    title="hit counter"
                                    alt="hit counter"
                                />
                            </a>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
