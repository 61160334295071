import { Facebook, GitHub, LinkedIn, Twitter, WhatsApp } from '@mui/icons-material';
import Mail from '@mui/icons-material/Mail';
import { Box, Container, Grid, Link, Typography, useTheme } from '@mui/material';
import BackgroundCover1 from '../Backgrounds/BackgroundCover1';
import { bgDark, bgLight } from '../config/colors';
import './ContactSlider.scss';

export default function ContactSlider() {
    const theme = useTheme();
    return (
        <Container
            id="contact"
            disableGutters={true}
            maxWidth={false}
            sx={{
                bgcolor: theme.palette.mode === 'light' ? bgLight : bgDark,
                color: theme.palette.mode === 'light' ? 'background.paper' : 'rgba(114, 0, 255, 1)',
                zIndex: 2,
                opacity: 0.9999,
            }}
        >
            <BackgroundCover1 />
            <Container sx={{ padding: '56px 16px' }}>
                <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
                    <Typography variant="h3" padding={3} color={'warning.light'}>
                        Contact Me
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item md={6} lg={6} xs={12} sm={12}>
                        <iframe
                            title="ayub"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27953.044157958437!2d88.10927737991872!3d25.06572319441968!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe63e40c17d5140a!2sAyub%20Ansari!5e0!3m2!1sen!2sin!4v1669051366535!5m2!1sen!2sin"
                            width="100%"
                            height="450"
                            style={{ border: '0', borderRadius: '2px' }}
                            // allowfullscreen={""}
                            loading="lazy"
                        ></iframe>
                    </Grid>

                    <Grid item md={6} lg={6} xs={12} sm={12}>
                        <Box padding={'32px'} className="contact">
                            <Box padding={3} className="contact-details">
                                <Typography variant="h4" color="headingText.main">
                                    Send a mail to me
                                </Typography>
                                <Typography
                                    marginTop={1.5}
                                    variant="h5"
                                    display={'flex'}
                                    alignItems="center"
                                    color={'descriptionText.main'}
                                >
                                    <Mail />
                                    <Link
                                        underline="none"
                                        color="descriptionText.main"
                                        target={'_blank'}
                                        href="mailto:EmailForAyub@gmail.com"
                                        sx={{
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        EmailForAyub@gmail.com
                                    </Link>
                                </Typography>
                            </Box>
                            <Box padding={3} className="contact-details">
                                <Typography variant="h4" color="headingText.main">
                                    Follow me{' '}
                                </Typography>
                                <Typography
                                    marginTop={1.5}
                                    variant="h5"
                                    display={'flex'}
                                    alignItems="center"
                                    color={'descriptionText.main'}
                                >
                                    <Facebook />
                                    <Link
                                        underline="none"
                                        color="descriptionText.main"
                                        target={'_blank'}
                                        href="//fb.com/iAmAyubAnsari"
                                    >
                                        iAmAyubAnsari
                                    </Link>
                                </Typography>

                                <Typography
                                    marginTop={1.5}
                                    variant="h5"
                                    display={'flex'}
                                    alignItems="center"
                                    color={'descriptionText.main'}
                                >
                                    <Twitter />
                                    <Link
                                        underline="none"
                                        color="descriptionText.main"
                                        target={'_blank'}
                                        href="//twitter.com/TheAyubAnsari"
                                    >
                                        TheAyubAnsari
                                    </Link>
                                </Typography>
                                <Typography
                                    marginTop={1.5}
                                    variant="h5"
                                    display={'flex'}
                                    alignItems="center"
                                    color={'descriptionText.main'}
                                >
                                    <LinkedIn />
                                    <Link
                                        underline="none"
                                        color="descriptionText.main"
                                        target={'_blank'}
                                        href="//linkedin.com/in/theAyubAnsari/"
                                    >
                                        TheAyubAnsari
                                    </Link>
                                </Typography>
                                <Typography
                                    marginTop={1.5}
                                    variant="h5"
                                    display={'flex'}
                                    alignItems="center"
                                    color={'descriptionText.main'}
                                >
                                    <GitHub />
                                    <Link
                                        underline="none"
                                        color="descriptionText.main"
                                        target={'_blank'}
                                        href="//github.com/iAyubAnsari"
                                    >
                                        iAyubAnsari
                                    </Link>
                                </Typography>
                                <Typography
                                    marginTop={1.5}
                                    variant="h5"
                                    display={'flex'}
                                    alignItems="center"
                                    color={'descriptionText.main'}
                                >
                                    <WhatsApp />
                                    <Link
                                        underline="none"
                                        color="descriptionText.main"
                                        target={'_blank'}
                                        href="//wa.me/919614028180"
                                    >
                                        Ayub Ansari
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
