import { Box, Card, Typography } from "@mui/material";

type MyProps = {
	title: string;
	imgUrl: string;
	description: string;
};

export default function ServiceCard(props: MyProps) {
	return (
		<Card sx={{ padding: 3, margin: 2 }}>
			<Box padding={3}>
				<img alt={'ayub-services'} style={{ width: "100%" }} src={require("../../../assets/" + props.imgUrl)} />
			</Box>
			<Box>
				<Typography variant='h5' fontWeight={"bold"} color={"headingText.main"}>
					{props.title}
				</Typography>
				<Typography variant='body2' paddingTop={2}>
					{props.description}
				</Typography>
			</Box>
		</Card>
	);
}
