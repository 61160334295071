// import "./AboutSlider.scss";
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BackgroundCover1 from '../Backgrounds/BackgroundCover1';
import WorkCard from '../common/WorkCard/WorkCard';
import { bgDark, bgLight } from '../config/colors';
import './WorksSlider.scss';
import Works from './../../data/works.json';

export default function WorksSlider() {
    const theme = useTheme();

    return (
        <Container
            id="works"
            disableGutters={true}
            maxWidth={false}
            sx={{
                bgcolor: theme.palette.mode === 'light' ? bgLight : bgDark,
                color: theme.palette.mode === 'light' ? 'background.paper' : 'rgba(114, 0, 255, 1)',
                zIndex: 2,
                opacity: 0.9999,
            }}
        >
            <BackgroundCover1 />
            <Container sx={{ padding: '56px 16px' }}>
                <Grid container justifyContent={'center'}>
                    <Grid item md={12} lg={12} xs={12} sm={12} textAlign="center">
                        <Typography variant="h3" padding={3} color={'warning.light'}>
                            Works I have done so far
                        </Typography>
                    </Grid>
                    {Works.map((item, index) => (
                        <Grid item md={3} lg={3} xs={12} sm={12} textAlign="center" key={index}>
                            <WorkCard
                                title={item.title}
                                thumbnail={`
                                /projects/${item.thumbnail}`}
                                image={`
                                /projects/${item.image}`}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Container>
    );
}
