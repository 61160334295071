import { DarkMode, LightMode } from '@mui/icons-material';
import { Box, Container, Grid, IconButton, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import NavDrawer from '../Drawer/Drawer';
import { ColorModeContext } from '../ThemeContext/ThemeContextProvider';
import './Nav.scss';

import LogoLight from './../../assets/logo-black.png';
import LogoDark from './../../assets/logo-white.png';

export const scroll = (e: any, id: string, pos: string = 'center') => {
    e.preventDefault();
    const section: any = document.querySelector(`#${id}`);
    section && section.scrollIntoView({ top: 100, behavior: 'smooth', block: pos });
};

export default function Nav() {
    const theme = useTheme();
    const colorMode = React.useContext(ColorModeContext);
    const [isNavVisible, setIsNavVisible] = useState<boolean>(false);
    const menus = require('./../../data/menus.json');
    const [screenWidth, setScreenWidth] = useState(window.screen.width);
    const themeMode = theme.palette.mode;

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 10) {
                setIsNavVisible(true);
            } else setIsNavVisible(false);
        });
        return () => {
            window.removeEventListener('scroll', () => {
                console.log('removed');
            });
        };
    }, []);

    return (
        <div
            className={`nav ${isNavVisible && 'nav__black'} ${
                themeMode === 'light' ? 'light-navbar-bg' : 'dark-navbar-bg'
            }`}
        >
            <Container>
                <Grid container>
                    <Grid item md={1} lg={1} xs={8} sm={8}>
                        <Box className="ayub">
                            {screenWidth <= 900 && <NavDrawer />}
                            <Link underline="none" className="logo-title">
                                <img alt="logo" src={theme.palette.mode === 'dark' ? LogoDark : LogoLight} />
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item md={4} lg={4} xs={1} sm={1}></Grid>
                    <Grid item md={6} lg={6} xs={1} sm={1}>
                        {screenWidth > 900 && (
                            <div className="menu-bar">
                                {menus.map((item: any, index: number) => (
                                    <Link
                                        onClick={(e) => scroll(e, item.title.toLowerCase())}
                                        href={item.url}
                                        underline="none"
                                        key={index}
                                        color={'descriptionText.main'}
                                    >
                                        {item.title}
                                    </Link>
                                ))}
                            </div>
                        )}
                    </Grid>
                    <Grid item md={1} lg={1} xs={2} sm={2}>
                        <IconButton
                            sx={{ borderRadius: '50%', position: 'absolute', top: '10px' }}
                            onClick={colorMode.toggleColorMode}
                            color="inherit"
                        >
                            {theme.palette.mode === 'dark' ? (
                                <LightMode
                                    style={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '3px',
                                    }}
                                />
                            ) : (
                                <DarkMode
                                    style={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderRadius: '50%',
                                        padding: '3px',
                                    }}
                                />
                            )}
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
