import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { scroll } from '../Nav/Nav';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function NavDrawer() {
    const [state, setState] = React.useState({
        left: false,
        right: false,
    });
    const menus = require('../../data/menus.json');
    const theme = useTheme();
    const themeMode = theme.palette.mode;

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    // https://api.quotable.io/random
    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box height="100vh" className={` ${themeMode === 'light' ? 'light-navbar-bg' : 'dark-navbar-bg'}`}>
                <Box padding={3} pt={5} mb={2}>
                    Hi,
                    <br />
                    Hope you are doing good today. Lets explore my portfolio together
                </Box>
                <Divider />
                <List>
                    {menus.map((item: any, index: number) => (
                        <ListItem
                            button
                            key={index}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                            <Link
                                onClick={(e) => {
                                    scroll(e, item.title.toLowerCase(), 'start');
                                    setTimeout(() => {
                                        toggleDrawer(anchor, false)(e);
                                    }, 700);
                                }}
                                href={item.url}
                                underline="none"
                                key={index}
                                color={'descriptionText.main'}
                            >
                                <ListItemText primary={item.title} />
                            </Link>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );

    return (
        <div>
            {(['left'] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <Link underline="none" onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon sx={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }} />
                    </Link>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
