import React, { FC, useState } from 'react';
import { Box, Button, Card, Modal, Typography } from '@mui/material';
import ModalImage from '@avidian/react-modal-image';

type MyProps = {
    title: string;
    image: string;
    thumbnail: string;
};
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function WorkCard(props: MyProps) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        if (open) {
            setOpen(false);
        }
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card sx={{ padding: 2, margin: 2 }}>
            <Box padding={1} onClick={handleOpen}>
                <Box
                    sx={{
                        filter: 'grayscale(100%)',
                        '&:hover': {
                            filter: 'grayscale(0%)',
                            cursor: 'pointer',
                        },
                    }}
                >
                    <img
                        style={{ width: '100%', height: '25vh', objectFit: 'cover' }}
                        src={`${props.thumbnail}`}
                        alt={props.title}
                        loading="lazy"
                    />
                </Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <Box
                        sx={{
                            ...style,
                            width: '50%',
                            maxHeight: '70%',
                            textAlign: 'center',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography variant="h5" padding={2} color={'warning.light'}>
                            {props.title}
                        </Typography>

                        <Box style={{ width: '100%', overflowY: 'scroll', maxHeight: '60vh' }}>
                            <img style={{ width: '100%' }} src={`${props.image}`} alt={props.title} loading="lazy" />
                        </Box>
                    </Box>
                </Modal>
            </Box>
            <Box>
                <Typography variant="body2" color={'warning.light'}>
                    {props.title}
                </Typography>
            </Box>
        </Card>
    );
}
