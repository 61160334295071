import { useEffect, useState } from 'react';
// import "./AboutSlider.scss";
import { Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BackgroundCover1 from '../Backgrounds/BackgroundCover1';
import ServiceCard from '../common/ServiceCard/ServiceCard';
import { bgDark, bgLight } from '../config/colors';

export default function ServicesSlider() {
    const [services, setServices] = useState<Array<{}>>([]);
    const theme = useTheme();

    useEffect(() => {
        let services = require('./../../data/services.json');
        setServices(services);

        //   return () => {
        // 	second
        //   }
    }, []);

    return (
        <Container
            id="services"
            disableGutters={true}
            maxWidth={false}
            sx={{
                bgcolor: theme.palette.mode === 'light' ? bgLight : bgDark,
                color: theme.palette.mode === 'light' ? 'background.paper' : 'rgba(114, 0, 255, 1)',
                zIndex: 2,
                opacity: 0.9999,
            }}
        >
            <BackgroundCover1 />
            <Container sx={{ padding: '56px 16px' }}>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} textAlign="center">
                        <Typography variant="h3" padding={3} color={'warning.light'}>
                            Services Offered
                        </Typography>
                    </Grid>

                    {services.map((item: any, index) => (
                        <Grid item md={4} lg={4} xs={12} sm={12} textAlign="center" key={index}>
                            {/* {console.log(require("../../assets/mobile.jpg"))} */}
                            <ServiceCard title={item.title} imgUrl={item.imageUrl} description={item.description} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Container>
    );
}
