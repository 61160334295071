// import "./AboutSlider.scss";
import { Box, Container, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BackgroundCover1 from '../Backgrounds/BackgroundCover1';
import { bgDark, bgLight } from '../config/colors';

export default function ExpSlider() {
    const theme = useTheme();
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    const mm = (currentMonth * 100) / 12;
    const mm2 = currentYear + mm / 100;

    const ex = Math.floor(mm2 - 2016.75);

    return (
        <Container
            disableGutters={true}
            maxWidth={false}
            sx={{
                bgcolor: theme.palette.mode === 'light' ? bgLight : bgDark,
                color: theme.palette.mode === 'light' ? 'background.paper' : 'rgba(114, 0, 255, 1)',
                zIndex: 2,
                opacity: 0.9999,
            }}
        >
            <BackgroundCover1 />
            <Container sx={{ padding: '56px 16px' }}>
                <Grid container>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <Box padding={2}>
                            <Typography variant="h3" color="warning.light">
                                Stack Experience
                            </Typography>
                            <Box color="descriptionText.main">
                                {/* <Typography> */}
                                {/* ## Stack Experience  */}
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Languages
                                    </Typography>{' '}
                                    - JavaScript, PHP, Python
                                </Typography>
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Frontend Libraries & Frameworks
                                    </Typography>{' '}
                                    - React JS with Typescript and Redux, Next JS and React Native
                                </Typography>
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Backend Libraries & Frameworks
                                    </Typography>{' '}
                                    - Node JS, Express JS, Laravel
                                </Typography>
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Databases
                                    </Typography>{' '}
                                    - MySQL, MongoDB, Firebase
                                </Typography>
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Testing
                                    </Typography>{' '}
                                    - Jest, SuperTest
                                </Typography>
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Tooling
                                    </Typography>{' '}
                                    - Git, Figma
                                </Typography>
                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Cloud
                                    </Typography>{' '}
                                    - AWS, Heroku, Mongo Atlas
                                </Typography>

                                <Typography variant="h6">
                                    <Typography
                                        color="headingText.main"
                                        variant="body1"
                                        display={'inline-block'}
                                        fontSize={'1.5rem'}
                                    >
                                        Mobile App Development
                                    </Typography>{' '}
                                    - Android (React Native)
                                </Typography>

                                {/* </Typography> */}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item lg={2} md={2} sm={12} xs={12}>
                        {' '}
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                        <Box padding={2}>
                            <Typography variant="h3" component="h3" color="warning.light">
                                {ex}+ Years of <br /> working experience
                            </Typography>
                            <Typography variant="h4" component="h4" color="headingText.main">
                                as a <br />
                                Full Stack Developer
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Container>
    );
}
