import { PaletteMode } from '@mui/material';
import { cyan } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export default function ThemeContextProvider({ children }: any) {
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

    // console.log({ darkThemeMq });
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                // alert("wait");
            },
        }),
        [mode]
    );
    const getDesignTokens = (mode: PaletteMode) => ({
        palette: {
            mode,
            ...(mode === 'light'
                ? {
                      // palette values for light mode
                      // primary: deepPurple,
                      // secondary: blue,
                      // divider: amber[200],
                      // text: {
                      // 	primary: blue[800],
                      // 	secondary: grey[800],
                      // },
                      descriptionText: {
                          main: '#ebe2e2',
                      },
                      headingText: {
                          main: cyan[500],
                      },
                  }
                : {
                      // palette values for dark mode

                      // primary: orange ,
                      // secondary: blueGrey,
                      // // divider: deepOrange[700],
                      // background: {
                      // 	default: deepOrange[900],
                      // 	paper: deepOrange[900],
                      // },
                      // // text: {
                      // // 	primary: pink[200],
                      // // 	secondary: pink[500],
                      // // }
                      // typography:{
                      // 	main:red,
                      // 	htmlfon:100
                      // }
                      descriptionText: {
                          main: 'white',
                      },
                      headingText: {
                          main: cyan[300],
                      },
                  }),
        },
    });

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
    useEffect(() => {
        if (darkThemeMq.matches) {
            setMode('dark');
        } else {
            setMode('light');
        }
    }, []);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
}
